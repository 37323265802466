<template>
  <div>
    <section>
      <!-- <v-chip-group mandatory show-arrows active-class="primary--text" v-model="selectedCat"
        @click="getQuestionsByGroupId">
        <v-chip v-for="cat in categories" :key="cat.id">
          {{ cat.value }}
        </v-chip>

      </v-chip-group> -->

      <div class="d-flex justify-content-between">
        <div>
          <span class="16 font-weight-bold">{{
              $t("Frequently questions")
          }}</span>
        </div>
        <div>
          <v-btn color="primary" depressed plain link min-width="auto" min-height="auto" height="auto" class="primary--text pa-1" to="/c/questions">
            {{ $t('Show all') }}
          </v-btn>
        </div>
      </div>

      <div class="mt-2" v-if="questions && questions.length > 0">
        <v-carousel height="auto" cycle hide-delimiter-background :show-arrows="false" :interval="4000" light>
          <v-carousel-item v-for="(item, i) in questions" :key="i">
            <v-card class="mx-2 mt-2 mb-12 d-flex flex-column justify-space-between border" elevation="0" hover ripple :to="`/c/questions/${item.id}`">
              <v-card-title class="d-flex justify-space-between align-items-center">
                <span v-if="item.answers" class="blue lighten-5 blue--text text--darken-1 p-2 rounded-lg f12"> {{ item.answers.length }}
                  {{ $t("Answer") }}</span>
                <span v-else class="blue lighten-5 blue--text text--darken-1 p-2 rounded-lg f12"> 0 {{ $t("Answer") }}</span>
                <span class="f13 font-weight-light"> {{ item.createDate | moment("from", "now") }} </span>
              </v-card-title>

              <v-card-text>
                <p class="f14 text-justify" v-html="item.question"></p>
              </v-card-text>
              
              <v-divider dark class="m-0"></v-divider>

              <v-card-actions class="d-flex justify-space-between pt-2 align-center">
                <div v-if="item.answers && item.answers.length">
                  <div class="d-flex flex-row align-items-center">
                    <section class="d-flex flex-row avatar-group right-to-left">
                      <span v-for="(answer, index) in item.answers.slice(0, 3)" :key="index"
                        class="avatars-group__item avatar">
                        <v-avatar size="36" color="primary" class="bordered">
                          <img :src="answer.doctorProfilePic" :alt="answer.doctorFullName ? answer.doctorFullName : ''"
                            v-if="answer.doctorProfilePic && index < 2" />

                          <span v-else-if="index < 2" class="white--text f12">
                            {{ answer.doctorFullName ? answer.doctorFullName.slice(0, 1) : "" }}
                          </span>

                          <span v-else class="white--text f14">
                            ...
                          </span>
                        </v-avatar>
                      </span>
                    </section>
                    <div>
                      <span class="f12 font-weight-normal ps-1" v-if="item.answers.length == 1">
                        {{ item.answers[0].doctorFullName ? item.answers[0].doctorFullName : '' }}
                      </span>
                      <span class="f12 font-weight-normal ps-1" v-if="item.answers.length > 1">
                        {{ item.answers.length }} {{ $t('specialist') }}
                      </span>
                    </div>
                  </div>
                </div>
                
                <div v-else>
                  <span class="bg-harmonies text-paris-pink p-2 rounded-lg f12">
                    {{ $t("Not answered") }}
                  </span>
                </div>
                <div>
                  <div class="text-left mt-1">
                    <div class="d-flex d-inline-flex align-items-center border border-primary rounded primary--text" v-if="item.userLikeQuestions && !item.userLikeQuestions.find(x => x.mobile == $store.getters.mobileNumber)">
                      <iconly type="bold" name="heart" class="ms-1 me-0 wh-20"></iconly>
                      <span v-if="item.userLikeQuestions && item.userLikeQuestions.length" class="me-1 f12">
                        {{
                            item.userLikeQuestions.length
                        }}
                      </span>
                      <span v-else class="me-1 f12">0</span>
                    </div>
                    <div class="d-flex d-inline-flex align-items-center primary rounded white--text" v-else>
                      <iconly type="bold" name="heart" class="ms-1 me-0 wh-20" color="white"></iconly>
                      <span v-if="item.userLikeQuestions && item.userLikeQuestions.length" class="me-1 f12">
                        {{
                            item.userLikeQuestions.length
                        }}
                      </span>
                      <span v-else class="me-1 f12">0</span>
                    </div>
                    <div class=" d-flex d-inline-flex align-items-center border border-primary rounded primary--text ms-2">
                      <iconly type="bold" name="show" class="ms-1 me-0 wh-20"></iconly>
                      <span class="me-1 f12">{{ item.userViews.length }}</span>
                    </div>
                  </div>
                </div>
              </v-card-actions>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </div>

      <span class="text-center f16 d-block mt-8" v-else>
        {{$t('No results found')}}
      </span>

      <!-- ---------------------------------------------------------------------- -->
      <!-- Podcasts -->
      <!-- ---------------------------------------------------------------------- -->
      <div class="mt-5 d-flex justify-content-between">
        <div>
          <span class="f14 font-weight-bold">{{ $t("Last podcasts") }}</span>
        </div>
        <div>
          <router-link to="/c/podcasts" class="text-decoration-none primary--text fw-bold">
          <span role="button" class="f12">{{
              $t("Show all")
            }}</span>
          </router-link>
        </div>
      </div>

      <div v-if="podcasts && podcasts.length > 0" class="my-5">
        <v-sheet class="mt-4" flat>
          <v-slide-group show-arrows>
            <v-slide-item v-for="(item, i) in podcasts" :key="i">
              <v-card class="d-flex flex-row align-items-center mb-2 pe-2 ps-0 border rounded-lg" :to="`/c/contents/podcast/${item.id}`" elevation="0" min-width="300">
                <v-img :lazy-src="require('@/assets/images/placeholder.png')" :src="item.picture != null ? item.picture : require('@/assets/images/placeholder.png')" :aspect-ratio="1 / 1" max-width="150"></v-img>
                <div class="d-flex flex-column ms-4 me-0">
                  <p class="f14 font-weight-bold" v-html="item.title "></p>

                  <span class="f14 font-weight-bold text-blue"  @click.prevent="openDrBottomSheet(item.publisher)">{{item.publisherName}}</span>
                  <span class="f13 font-weight-light align-items-center d-flex grey--text">
                  <i class="fal fa-clock me-1 ms-0"></i>
                  {{ item.createDate | moment("from", "now") }}
                </span>
                </div>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </div>

      <span class="text-center f16 d-block mt-8 mb-4" v-else>
        {{$t('No results found')}}
      </span>

      <!-- ---------------------------------------------------------------------- -->
      <!-- Posts -->
      <!-- ---------------------------------------------------------------------- -->
      <div class="d-flex justify-content-between">
        <div>
          <span class="16 font-weight-bold">{{
              $t("Latest posts")
          }}</span>
        </div>
        <div>
          <v-btn color="primary" depressed plain link min-width="auto"
                 min-height="auto" height="auto" class="primary--text pa-1" to="/c/contents">
            {{ $t('Show all') }}
          </v-btn>
        </div>
      </div>

      <v-row v-if="posts && posts.length > 0">
        <v-col xs="6" sm="6" md="4" lg="3" xl="3" cols="6" v-for="(post, index) in posts" :key="index">
          <v-card :to="`/c/contents/${post.type.toLowerCase()}/${post.id}`" class="rounded-lg">
            <v-img :lazy-src="require('@/assets/images/placeholder.png')" :src="post.picture" :aspect-ratio="1 / 1" class="d-flex align-items-center justify-content-center">
              <div class="text-white w-100 text-center">
                <i class="fas fa-play p-4 bg-black-50 rounded-pill" v-if="post.type == 'VIDEO'"></i>
              </div>
            </v-img>

            <v-card-title class="f13 single-line pb-0">
              {{ post.title }}
            </v-card-title>

            <v-card-actions @click.prevent="openDrBottomSheet(post.publisher)" class="pt-0">
              <v-list-item two-line class="p-0">
                <v-list-item-avatar>
                  <v-img :lazy-src="require('@/assets/images/profile-circle.png')" :src="post.publisherPic"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="f12 font-weight-bold primary--text">{{ post.publisherName }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="f10">{{ post.createDate | moment("from", "now") }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <span class="text-center f16 d-block mt-8" v-else>
        {{$t('No results found')}}
      </span>

    </section>

    <BshDoctor ref="drBottomSheet" />
  </div>
</template>
<script>
import BshDoctor from "@/components/DoctorProfile.vue";
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();


export default {
  components: {
     BshDoctor
  },
  data() {
    return {
      categories: [],
      selectedCat: 0,
      questions: [],
      posts: [],
      podcasts: null,

    };
  },
  methods: {
    openDrBottomSheet(mobile){
      this.$refs.drBottomSheet.openWithDoctorMobile(mobile);
    },
    getAllCategories() {
      apiService.getActivities()
        .then((response) => {
          this.categories = response.data;
          var first = {
            id: -1,
            value: "همه"
          };

          this.categories.unshift(first)
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getCommonQuestions() {
      apiService.UserQuestion().then((response) => {
        this.questions = response.data;
      });
    },
    getLatestPosts() {
      apiService.getLatestPosts(0, 6).then((response) => {
        this.posts = response.data;
      });
    },
    PodCastPublished() {
      apiService.getPodcastspublishPaging(0, 4).then((response) => {
        this.podcasts = response.data.data;
      });
    },
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);
    this.getAllCategories();
    this.getCommonQuestions();
    this.getLatestPosts();
    this.PodCastPublished();

  },
};
</script>